import { SeriesEntry } from "./types";

export const getProductPrice = (productSeries: SeriesEntry[]) => {
  if (productSeries.length == 0) {
    return 0;
  }
  return productSeries[productSeries.length - 1].value.toFixed(2);
};

export const getDailyPnl = (series: SeriesEntry[]) => {
  if (series.length <= 1) {
    return 0;
  }
  const last = series[series.length - 1].value;
  const prev = series[series.length - 2].value;
  return (((last - prev) / prev) * 100).toFixed(2);
};

export const getTotalPnl = (series: SeriesEntry[]) => {
  if (series.length <= 1) {
    return 0;
  }
  const last = series[series.length - 1].value;
  const prev = series[0].value;
  return (((last - prev) / prev) * 100).toFixed(0);
};

const yearToDate = (date: Date) => {
  const year = date.getFullYear();
  const newDate = new Date(year, 0, 1);
  return newDate;
};

export const getYearToDate = (series: SeriesEntry[]) => {
  if (series.length <= 1) {
    return 0;
  }
  const current = new Date(series[series.length - 1].time * 1000);
  const date = yearToDate(current);
  const dateUnix = date.getTime() / 1000;

  const first = series.find((x) => x.time > dateUnix);
  if (first) {
    const currentNav = series[series.length - 1].value;
    const ytd = (currentNav / first.value) * 100 - 100;
    return (ytd > 0 ? "+" : "") + ytd.toFixed(0);
  } else {
    console.error(`Could not find entry at start of ${dateUnix}`);
  }
  return 0;
};

export const getStartDate = (series: SeriesEntry[]) => {
  if (series.length == 0) {
    return 0;
  }
  const first = series[0].time;
  return new Date(first * 1000).toLocaleString().split(", ")[0];
};

export const getPnlColor = (value: number | string) => {
  if (typeof value === "string") {
    value = parseFloat(value);
  }
  return value < 0
    ? "text-rose-400 bg-rose-500/[.06]"
    : "text-teal-500 bg-teal-500/[.06]";
};

export const getPnlTextColor = (value: number | string) => {
  if (typeof value === "string") {
    value = parseFloat(value);
  }
  return value < 0 ? "text-rose-400" : "text-teal-500";
};

export const hslToHex = (h: number, s: number, l: number) => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

const subtractMonths = (date: Date, months: number) => {
  const newDate = new Date(date.getTime());
  newDate.setMonth(newDate.getMonth() - months);
  return newDate;
};

const subtractDays = (date: Date, days: number) => {
  const newDate = new Date(date.getTime());
  newDate.setDate(newDate.getDate() - days);
  return newDate;
};

export const mapRange = {
  "1D": (d: Date) => subtractDays(d, 1),
  "7D": (d: Date) => subtractDays(d, 7),
  "1M": (d: Date) => subtractMonths(d, 1),
  "3M": (d: Date) => subtractMonths(d, 3),
  "1Y": (d: Date) => subtractMonths(d, 12),
  YTD: (d: Date) => yearToDate(d),
} as Record<string, (d: Date) => Date>;
