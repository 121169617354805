import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  BarPrice,
  createChart,
  IChartApi,
  ISeriesApi,
  LineData,
  LineStyle,
  LineWidth,
  MouseEventParams,
} from "lightweight-charts";
import { Index, SeriesEntry } from "../types";

const config = {
  height: 600,
  grid: {
    vertLines: {
      color: "rgba(197, 203, 206, 0.05)",
    },
    horzLines: {
      color: "rgba(197, 203, 206, 0.05)",
    },
  },
  crosshair: {
    horzLine: {
      visible: true,
      labelVisible: true,
    },
    vertLine: {
      visible: true,
      labelVisible: true,
    },
  },
  rightPriceScale: {
    visible: true,
    borderColor: "rgba(197, 203, 206, .1)",
    borderVisible: true,
  },
  leftPriceScale: {
    visible: true,
    borderColor: "rgba(197, 203, 206, .1)",
    borderVisible: true,
  },
  layout: {
    backgroundColor: "hsla(235, 17%, 14%, 0)",
    textColor: "rgba(255, 255, 255, 0.9)",
    // fontFamily: "Montserrat",
    fontSize: 12,
  },
  timeScale: {
    timeVisible: false,
    secondsVisible: true,
    borderVisible: true,
    // fixLeftEdge: true,
  },
};

interface ChartProps {
  index: Index;
  series: SeriesEntry[];
  productSeries: SeriesEntry[];
  customOptions?: Record<string, any>;
}

const toolTipWidth = 80;
const toolTipHeight = 80;
const toolTipMargin = 5;

function Chart({ index, series, productSeries, customOptions }: ChartProps) {
  const ref = useRef<HTMLDivElement>(null);
  const tooltip = useRef<HTMLDivElement>(null);
  const chart = useRef<IChartApi | null>(null);
  const [indexPrice, setIndexPrice] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const productLineSeries = useRef<ISeriesApi<"Area">>();
  const indexLineSeries = useRef<ISeriesApi<"Area">>();

  const handleResize = useCallback(() => {
    if (chart.current && ref.current) {
      chart.current?.resize(
        ref.current.clientWidth,
        ref.current.clientHeight,
        true
      );
      chart.current?.timeScale().fitContent();
    }
  }, []);

  const businessDayToString = (businessDay: any) => {
    return businessDay.year + "-" + businessDay.month + "-" + businessDay.day;
  };

  useEffect(() => {
    chart.current = createChart(ref.current as HTMLElement, {
      ...config,
      ...customOptions,
    });
    const color = "#6b7cff";
    const options = {
      topColor: `${color}44`,
      bottomColor: `${color}00`,
      lineColor: color,
      crossHairMarkerVisible: false,
      lineWidth: 2 as LineWidth,
      title: "BTC",
    };

    // Index series
    indexLineSeries.current = chart.current.addAreaSeries(options);
    indexLineSeries.current.setData(series as LineData[]);

    // Product series
    const _productLineSeries = chart.current.addAreaSeries({
      ...options,
      lineColor: "#d080ff",
      title: "Index NAV",
    });
    _productLineSeries.setData(productSeries as LineData[]);
    console.log(_productLineSeries);
    productLineSeries.current = _productLineSeries;

    chart.current.timeScale().fitContent();

    // Horizontal 100 line
    indexLineSeries.current.createPriceLine({
      price: 100.0,
      color: `#294bb330`,
      lineWidth: 3,
      lineStyle: LineStyle.Solid,
      axisLabelVisible: true,
      title: "",
    });
    // subscribeMovement(chart.current);
    window.addEventListener("resize", handleResize);
  }, []);

  // const setDataBackup = async (lineSeries: ISeriesApi<any>, chart: IChartApi) => {
  //     try {
  //         const response = await fetch("https://api.binance.com/api/v3/klines?symbol=BTCUSDT&interval=1d");
  //         const data = await response.json();
  //         const series = data.map((k: Record<string, any>) => ({
  //             time: k[0] / 1000,
  //             value: parseFloat(k[4])
  //         }));
  //         lineSeries.setData(series);
  //         chart.timeScale().fitContent();
  //     } catch (e) {
  //         console.error(`Failed to fetch price of index: ${index.id}`);
  //     }
  // }

  return (
    <div ref={ref} style={{ position: "relative" }}>
      <div
        ref={tooltip}
        className="price-container bg-white/[.05] py-3 px-8 rounded flex flex-col items-center justify-center"
        style={{
          position: "absolute",
          boxSizing: "border-box",
          display: "none",
          zIndex: 100,
        }}
      >
        <div className="font-bold">
          Index: <div className=" text-lg text-indigo-100">{indexPrice}</div>
        </div>
        <div className="font-bold">
          <div className="text-white/[.8]">Product</div>
          <div className="text-lg text-indigo-100">{productPrice}</div>
        </div>
      </div>
    </div>
  );
}

export default Chart;
