import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  createChart,
  IChartApi,
  LineData,
  LineStyle,
  LineWidth,
} from "lightweight-charts";
import { IndexEntry, IndicesData } from "../types";
import { hslToHex } from "../utils";

const config = {
  height: 600,
  rightPriceScale: {
    visible: true,
    borderColor: "rgba(197, 203, 206, 1)",
    borderVisible: false,
  },
  leftPriceScale: {
    visible: true,
    borderColor: "rgba(197, 203, 206, 1)",
    borderVisible: false,
  },
  layout: {
    backgroundColor: "hsla(235, 17%, 14%, 0)",
    textColor: "rgba(255, 255, 255, 0.9)",
    // fontFamily: 'Montserrat',
    fontSize: 10,
  },
  grid: {
    vertLines: {
      color: "rgba(197, 203, 206, 0.05)",
    },
    horzLines: {
      color: "rgba(197, 203, 206, 0.05)",
    },
  },
  crosshair: {
    horzLine: {
      visible: false,
      labelVisible: true,
    },
    vertLine: {
      visible: true,
      style: 0,
      color: "rgba(32, 38, 46, 0.1)",
      labelVisible: true,
    },
  },
  timeScale: {
    timeVisible: false,
    secondsVisible: true,
    borderVisible: false,
  },
};

interface ChartProps {
  indexData?: IndicesData;
  selectedProducts: Record<string, boolean>;
}

const colors = {
  "BENCHMARK:BTC": "#ffb8a8",
  "BENCHMARK:ETH": "#a8bbff",
} as Record<string, string>;

function MultiChart({ indexData, selectedProducts }: ChartProps) {
  const ref = useRef<HTMLDivElement>(null);
  const chart = useRef<IChartApi | null>(null);

  const [seriesMap, setSeriesMap] = useState<Record<string, any>>({});
  const [skipFirst, setSkipFirst] = useState<boolean>(false);

  const getOptions = (index: number, indexName: string) => {
    const start = 170;

    const color = "#ffffff";
    const options = {
      topColor: `${color}00`,
      bottomColor: `${color}00`,
      lineColor: color,
      crossHairMarkerVisible: false,
      lineWidth: 2 as LineWidth,
      title: "Index",
    };
    const length = Object.entries(indexData?.data as any).length;
    const currentOptions = {
      ...options,
      lineColor: hslToHex(start + (index / length) * (360 - start), 70, 70),
      title: indexName,
    } as any;

    if (indexName.startsWith("BENCHMARK")) {
      currentOptions["lineStyle"] = 1 as LineStyle;
      currentOptions["lineColor"] = colors[indexName];
      currentOptions["title"] = currentOptions["title"].replace(
        "BENCHMARK:",
        ""
      );
    }
    if (indexName.startsWith("AGGA")) {
      currentOptions["title"] = currentOptions["title"].replace(
        "AGGA:DAISY:",
        ""
      );
    }
    return currentOptions;
  };

  const setup = (
    refresh: boolean = false,
    newIndexData: IndicesData | null = null
  ) => {
    console.log("--> setup");
    if (refresh && newIndexData != null && Object.keys(seriesMap).length > 0) {
      console.log("multichart refresh");

      Object.entries(newIndexData?.data).forEach(([indexName, series]) => {
        // console.log(`remove ${indexName}`);
        try {
          (chart.current as any).removeSeries(seriesMap[indexName]);
          setSeriesMap((m) => ({ ...m, [indexName]: null }));
        } catch (e) {}
      });
      Object.entries(newIndexData?.data)
        .map(([k, v], i) => [k, v, i])
        .filter(
          ([indexName, series, i]) => selectedProducts[indexName as string]
        )
        .forEach(([indexName, series, i]) => {
          // console.log(`add ${indexName}`);
          const newLineSeries = (series as IndexEntry[])
            .map((k: Record<string, any>) => ({
              time: k.time / 1000,
              value: parseFloat(k.price),
            }))
            .sort((a: any, b: any) => a.time - b.time);
          const options = getOptions(i as number, indexName as string);
          const lineSeries = chart.current?.addAreaSeries(options);
          (lineSeries as any).setData(newLineSeries as any);
          setSeriesMap((m) => ({ ...m, [indexName as string]: lineSeries }));
        });
      if (chart.current != null) {
        chart.current.timeScale().fitContent();
      }
      return;
    }

    if (chart != null && chart.current != null && indexData?.data != null) {
      if (!skipFirst) {
        setSkipFirst(true);
        return;
      }
      // Remove
      Object.entries(indexData?.data)
        .filter(
          ([indexName, series]) =>
            !selectedProducts[indexName] && seriesMap[indexName] != null
        )
        .forEach(([indexName, series]) => {
          (chart.current as any).removeSeries(seriesMap[indexName]);
          setSeriesMap((m) => ({ ...m, [indexName]: null }));
        });

      // Add
      Object.entries(indexData?.data)
        .map(([k, v], i) => [k, v, i])
        .filter(
          ([indexName, series, i]) =>
            selectedProducts[indexName as string] &&
            seriesMap[indexName as string] == null
        )
        .forEach(([indexName, series, i]) => {
          const newLineSeries = (series as IndexEntry[])
            .map((k: Record<string, any>) => ({
              time: k.time / 1000,
              value: parseFloat(k.price),
            }))
            .sort((a: any, b: any) => a.time - b.time);
          const options = getOptions(i as number, indexName as string);
          const lineSeries = chart.current?.addAreaSeries(options);
          (lineSeries as any).setData(newLineSeries as any);
          setSeriesMap((m) => ({ ...m, [indexName as string]: lineSeries }));
        });
      console.log("update selected products");
      return;
    }

    console.log("- initialize chart");
    chart.current = createChart(ref.current as HTMLElement, {
      ...config,
    });

    if (!indexData) {
      return;
    }

    if (!chart || !chart.current) {
      return;
    }

    Object.entries(indexData?.data).forEach(([indexName, series], i) => {
      if (chart == null || chart.current == null) {
        return;
      }

      // console.log(selectedProducts);
      if (!selectedProducts[indexName]) {
        // console.log(`Skip ${indexName}`);
        return;
      }

      const currentOptions = getOptions(i, indexName);
      const lineSeries = chart.current.addAreaSeries(currentOptions);
      setSeriesMap((m) => ({ ...m, [indexName]: lineSeries }));

      const newLineSeries = series
        .map((k: Record<string, any>) => ({
          time: k.time / 1000,
          value: parseFloat(k.price),
        }))
        .sort((a: any, b: any) => a.time - b.time);
      lineSeries.setData(newLineSeries as LineData[]);
    });

    chart.current.timeScale().applyOptions({
      fixLeftEdge: true,
      rightOffset: 20,
    });
    chart.current.timeScale().fitContent();
  };

  useEffect(() => {
    console.log("-- setup");
    setup();
  }, [selectedProducts]);

  useEffect(() => {
    console.log("-- setup refresh");
    setup(true, indexData);
  }, [indexData]);

  // useEffect(() => {}, []);

  return <div ref={ref}>{/* <div>{price}</div> */}</div>;
}

export default MultiChart;
