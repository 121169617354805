import { useEffect, useState } from "react";
import { Index, IndexEntry, SeriesEntry } from "./types";
import { getYearToDate } from "./utils";

import * as config from "./config";

interface SummaryItem {
  nav: string;
  weeklyReturn: string;
  ytd: string;
}

interface BlocksProps {
  funds: Index[];
  data: Record<string, IndexEntry[]> | undefined;
}

export default ({ funds, data }: BlocksProps) => {
  const getReturnColor = (returns: string) => {
    return parseFloat(returns) < 0 ? "text-rose-400" : "text-teal-500";
  };

  const [summary, setSummary] = useState<Record<string, SummaryItem>>({});

  const formatNav = (value: number) =>
    value != null ? `${value.toFixed(2)}` : "-";

  const formatReturn = (value: number | string) => {
    if (value == null) {
      return "-";
    }
    if (typeof value === "string") {
      value = parseFloat(value);
    }
    return value > 0 ? `+${value.toFixed(0)}` : `${value.toFixed(0)}`;
  };

  useEffect(() => {
    const sumDict = {} as Record<string, SummaryItem>;

    if (data !== undefined) {
      Object.entries(data).forEach(([key, value]) => {
        const x =
          (value[value.length - 1].price / value[value.length - 8].price - 1) *
          100;
        if (x == null) {
          console.error(key);
        }
        const weeklyReturns = formatReturn(x);

        const series: SeriesEntry[] = value.map((x) => ({
          time: x.time / 1000,
          value: x.price,
        }));
        const ytd = formatReturn(getYearToDate(series));
        const item = {
          nav: formatNav(value[value.length - 1].price),
          weeklyReturn: weeklyReturns,
          ytd: ytd,
        };
        sumDict[key] = item;
      });
    }

    setSummary(sumDict);
  }, [data]);

  return data != null ? (
    <div className="select-none px-4 mb-4 gap-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
      {funds
        .map(
          (f) => [f.id, config.blockData[f.id]] as [string, Record<string, any>]
        )
        .filter(([daisy, value]) => summary[daisy] != null)
        .map(([daisy, value]) => (
          <a
            key={daisy}
            href={value.link}
            className="border border-transparent hover:border-indigo-500/[.2] transition duration-150 ease-out py-6 px-8 space-y-4 font-medium rounded-xl bg-white/[.01]"
          >
            <div className="text-white">{value.title}</div>
            <div className="flex flex-row space-x-4 items-start">
              <div className="flex flex-col">
                <div className="text-xs text-white/60">NAV</div>
                <div className="text-4xl md:text-5xl text-indigo-300">
                  €{summary[daisy].nav}
                </div>
              </div>

              <div className="grid grid-cols-2 gap-3">
                <div className="flex flex-col justify-end">
                  <div className="text-xs text-white/60 mt-auto">YTD %</div>
                  <div
                    className={`text-md ${getReturnColor(summary[daisy].ytd)}`}
                  >
                    {summary[daisy].ytd}%
                  </div>
                </div>

                <div className="flex flex-col justify-end">
                  <div className="text-xs text-white/60 mt-auto">Weekly %</div>
                  <div
                    className={`text-md ${getReturnColor(
                      summary[daisy].weeklyReturn
                    )}`}
                  >
                    {summary[daisy].weeklyReturn}%
                  </div>
                </div>
              </div>
            </div>
            <div className="text-white/80 text-sm">{value.description}</div>
          </a>
        ))}
    </div>
  ) : null;
};
