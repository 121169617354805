import React, { useCallback, useEffect, useRef, useState } from "react";
import * as config from "./config";

interface TimeRangeSelectorProps {
  update: (range: string) => void;
}

export default function TimeRangeSelector({ update }: TimeRangeSelectorProps) {
  const [active, setActive] = useState<string>("3M");

  return (
    <div className="rounded-md bg-white/[.04] p-1 flex flex-row space-x-1 ">
      {config.options.map((option) => (
        <div
          key={option}
          onClick={() => {
            setActive(option);
            update(option);
          }}
          className={`text-white ${
            option == active && "bg-white/10"
          } rounded font-semibold hover:bg-white/5 cursor-pointer px-2 py-1 text-sm transition duration-100 ease-out`}
        >
          {option}
        </div>
      ))}
    </div>
  );
}
