import { SeriesEntry } from "../types";

export enum AggregationType {
  MONTHLY,
  YEARLY,
}

export const groupSeries = (
  series: SeriesEntry[],
  aggType: AggregationType
): Record<string, SeriesEntry[]> =>
  series.reduce((acc: Record<string, SeriesEntry[]>, it: SeriesEntry) => {
    const date = new Date(it.time * 1000);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const key =
      aggType === AggregationType.MONTHLY ? `${year}-${month}` : `${year}`;
    if (key in acc) {
      return {
        ...acc,
        [key]: acc[key].concat(it),
      };
    }
    return {
      ...acc,
      [key]: [it],
    };
  }, {});

export const getPnl = (
  groupedSeries: Record<string, SeriesEntry[]>
): Record<string, number> =>
  Object.entries(groupedSeries).reduce(
    (acc: Record<string, number>, [key, values]: [string, SeriesEntry[]]) => {
      if (values.length < 2) {
        return { ...acc, [key]: 0 };
      }
      const pnl = (values[values.length - 1].value / values[0].value - 1) * 100;
      return { ...acc, [key]: pnl };
    },
    {}
  );

export const formatTable = (
  pnlPerMonth: Record<string, number>,
  pnlPerYear: Record<string, number>
): Record<string, number[]> => {
  const years = Object.keys(pnlPerYear);
  const result: Record<string, number[]> = years.reduce(
    (acc, it) => ({ ...acc, [it]: [] }),
    {}
  );

  years.forEach((year) => {
    for (let i = 1; i <= 12; i++) {
      const key = `${year}-${i}`;
      result[year] = result[year].concat([pnlPerMonth[key]]);
    }
    result[year] = result[year].concat([pnlPerYear[year]]);
  });
  return result;
};

export const getMonthlyReturns = (series: SeriesEntry[]) => {
  const groupedPerMonth = groupSeries(series, AggregationType.MONTHLY);
  const groupedPerYear = groupSeries(series, AggregationType.YEARLY);
  const pnlPerMonth = getPnl(groupedPerMonth);
  const pnlPerYear = getPnl(groupedPerYear);
  return formatTable(pnlPerMonth, pnlPerYear);
};
