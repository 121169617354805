import React, { useEffect, useState } from "react";
import "./App.css";
import Chart from "./components/Chart";
import { Index, IndexEntry, IndicesData, SeriesEntry } from "./types";
import { useNavigate } from "react-router-dom";
import {
  getDailyPnl,
  getPnlColor,
  getProductPrice,
  getTotalPnl,
  getYearToDate,
} from "./utils";
import { getMonthlyReturns } from "./core/tableCalculations";
import ReturnsTable from "./ReturnsTable";
import LinkIcon from "./icons/LinkIcon";

const links = {
  "AGGA:DAISY:DEFI20": "https://agga.capital/what-we-offer/defi-twenty/",
  "AGGA:DAISY:BE50": "https://agga.capital/what-we-offer/defi-twenty/",
  "AGGA:DAISY:META15": "https://agga.capital/what-we-offer/defi-twenty/",
};

interface IndexComponentProps {
  index: Index;
  categories: string[];
  _indexSeries: IndexEntry[];
  _productSeries: IndexEntry[];
}

export default ({
  index,
  categories,
  _indexSeries,
  _productSeries,
}: IndexComponentProps) => {
  const navigate = useNavigate();
  const [indexSeries, setIndexSeries] = useState<SeriesEntry[]>([]);
  const [productSeries, setProductSeries] = useState<SeriesEntry[]>([]);
  const [showReturns, setShowReturns] = useState<boolean>(true);

  useEffect(() => {
    fetchPrices();
  }, []);

  const mapSeries = (series: any): SeriesEntry[] =>
    series
      .map((k: Record<string, any>) => ({
        time: k.time / 1000,
        value: parseFloat(k.price),
      }))
      .sort((a: any, b: any) => a.time - b.time);

  const fetchPrices = async () => {
    const index_series = mapSeries(_indexSeries);
    const product_series = mapSeries(_productSeries);
    setProductSeries(product_series);
    setIndexSeries(index_series);
  };

  // const returnsData = {
  //   2023: [
  //     -1.2, -1.2, -1.2, -1.2, -1.2, -1.2, -1.2, -1.2, -1.2, -1.2, -1.2, -1.2,
  //     1000,
  //   ],
  //   2022: [1.2, 11.2, 6.7, 13.4, 20.1, 120, 1.2, 1.2, 1.2, 1.2, 1.2, 10.2, 10],
  // };
  const returnsData = getMonthlyReturns(productSeries);

  return (
    <div
      // onClick={() =>
      //   navigate(`/index`, { state: { index, indexSeries, productSeries } })
      // }
      key={index.id}
      className="group bg-white/[.01] select-none p-8 hover:bg-white/[.01] md:rounded-2xl transition duration-150 ease-out grid grid-cols-1 md:grid-cols-3 border border-transparent _hover:border-indigo-500/[.2] md:m-4"
    >
      <div className="flex flex-col items-start">
        <a
          href={index.link}
          className="text-xl font-medium text-gray-100 hover:underline flex flex-row"
        >
          {index.name}
          <div className="flex flex-row items-center visible md:invisible transition-all duration-100 group-hover:visible text-sm font-bold ml-4 bg-white/[.05] hover:bg-white/20 p-1.5 rounded">
            <LinkIcon />
          </div>
        </a>

        <div className="flex flex-row flex-wrap space-x-1">
          {categories.map((c) => (
            <div className="text-xs mt-4 font-medium bg-white/5 hover:bg-white/10 px-3 py-1 rounded-full text-gray-300">
              {c}
            </div>
          ))}
        </div>

        <div className="flex-row items-start grid grid-cols-2 lg:grid-cols-4 gap-3 mt-6">
          <div>
            <div className="text-sm font-bold text-gray-500">NAV</div>
            <div className="flex flex-row items-center">
              <div className="text-lg font-bold mt-1 text-indigo-400">
                €{getProductPrice(productSeries)}
              </div>
            </div>
          </div>

          {/* <div className="flex flex-col items-start">
            <div className="text-sm font-bold text-gray-500 mb-1">
              Total Returns
            </div>
            <div
              className={`text-sm rounded-md px-3 py-1 font-bold flex flex-grow-0 ${getPnlColor(
                getTotalPnl(productSeries)
              )}`}
            >
              {getTotalPnl(productSeries)}%
            </div>
          </div> */}

          <div className="flex flex-col items-start">
            <div className="text-sm font-bold text-gray-500 mb-1">
              YTD Returns
            </div>
            <div
              className={`text-sm rounded-md px-3 py-1 font-bold flex flex-grow-0 ${getPnlColor(
                getYearToDate(productSeries)
              )}`}
            >
              {getYearToDate(productSeries)}%
            </div>
          </div>
          <div className="flex flex-col items-start">
            <div className="text-sm font-bold text-gray-500 mb-1">
              Daily Returns
            </div>
            <div
              className={`text-sm rounded-md px-3 py-1 font-bold flex flex-grow-0 ${getPnlColor(
                getDailyPnl(productSeries)
              )}`}
            >
              {getDailyPnl(productSeries)}%
            </div>
          </div>
        </div>

        <button
          className="px-3 mt-auto py-2 font-bold text-sm  bg-white/[.05] hover:bg-white/[.1] transition-all rounded hidden md:block"
          onClick={() => setShowReturns((v) => !v)}
        >
          {showReturns ? "Hide" : "Show"} monthly returns
        </button>
        {/* <div className="text-sm font-bold text-gray-500 mt-6">Start date</div> */}
        {/* <div className="text-sm font-bold">{getStartDate(productSeries)}</div> */}
      </div>

      <div className="col-span-2 p-2">
        {indexSeries && indexSeries.length > 0 ? (
          <Chart
            index={index}
            series={indexSeries}
            productSeries={productSeries}
          />
        ) : (
          <div className="text-white" style={{ height: 300 }}>
            loading...
          </div>
        )}
      </div>

      {showReturns && (
        <div className="col-span-3 mt-12 overflow-scroll hidden md:block">
          <div className="mt-3">
            <ReturnsTable returnsData={returnsData} />
          </div>
        </div>
      )}
    </div>
  );
};
