import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  createChart,
  IChartApi,
  LineData,
  LineStyle,
  LineWidth,
} from "lightweight-charts";
import { Index, SeriesEntry } from "../types";

const config = {
  height: 300,
  rightPriceScale: {
    visible: true,
    borderColor: "rgba(197, 203, 206, 0.05)",
    borderVisible: true,
  },
  leftPriceScale: {
    visible: false,
    borderColor: "rgba(197, 203, 206, 0.05)",
    borderVisible: true,
  },
  layout: {
    backgroundColor: "hsla(235, 17%, 100%, 0)",
    textColor: "rgba(255, 255, 255, 0.9)",
    // fontFamily: 'Montserrat',
    fontSize: 12,
  },
  grid: {
    vertLines: {
      color: "rgba(197, 203, 206, 0.00)",
    },
    horzLines: {
      color: "rgba(197, 203, 206, 0.00)",
    },
  },
  crosshair: {
    horzLine: {
      visible: false,
      labelVisible: true,
    },
    vertLine: {
      visible: true,
      style: 0,
      color: "rgba(32, 38, 46, 0.1)",
      labelVisible: true,
    },
  },
  timeScale: {
    timeVisible: false,
    secondsVisible: true,
    borderVisible: false,
  },
};

interface ChartProps {
  index: Index;
  series: SeriesEntry[];
  productSeries: SeriesEntry[];
  customOptions?: Record<string, any>;
}

function Chart({ index, series, productSeries, customOptions }: ChartProps) {
  const ref = useRef<HTMLDivElement>(null);
  const chart = useRef<IChartApi | null>(null);
  const [price, setPrice] = useState(0);

  const handleResize = useCallback(() => {
    if (chart.current && ref.current) {
      chart.current?.resize(
        ref.current.clientWidth,
        ref.current.clientHeight,
        true
      );
      chart.current?.timeScale().fitContent();
    }
  }, []);

  useEffect(() => {
    chart.current = createChart(ref.current as HTMLElement, {
      ...config,
      ...customOptions,
    });
    chart.current.timeScale().applyOptions({
      fixLeftEdge: true,
      rightOffset: 20,
    });
    const color = "#6b7cff";
    const options = {
      topColor: `${color}22`,
      bottomColor: `${color}00`,
      lineColor: color,
      crossHairMarkerVisible: false,
      lineWidth: 2 as LineWidth,
      title: "BTC",
    };

    const lineSeries = chart.current.addAreaSeries(options);
    lineSeries.setData(series as LineData[]);
    const productLineSeries = chart.current.addAreaSeries({
      ...options,
      lineColor: "#d080ff",
      lineWidth: 2 as LineWidth,
      // lineColor: "#70bcff",
      title: "NAV",
    });
    productLineSeries.setData(productSeries as LineData[]);

    chart.current.timeScale().fitContent();
    lineSeries.createPriceLine({
      price: 100.0,
      color: `#b3c7ff30`,
      lineWidth: 1,
      lineStyle: LineStyle.Dotted,
      axisLabelVisible: false,
      title: "",
    });

    function myCrosshairMoveHandler(param: any) {
      if (!param.point) {
        setPrice(0);
        return;
      }
      console.log(
        `Crosshair moved to ${param.point.x}, ${param.point.y}. The time is ${param.time}.`
      );
      setPrice(param.point.y);
    }
    // chart.current.subscribeCrosshairMove(myCrosshairMoveHandler);

    window.addEventListener("resize", handleResize);
  }, []);

  // const setDataBackup = async (lineSeries: ISeriesApi<any>, chart: IChartApi) => {
  //     try {
  //         const response = await fetch("https://api.binance.com/api/v3/klines?symbol=BTCUSDT&interval=1d");
  //         const data = await response.json();
  //         const series = data.map((k: Record<string, any>) => ({
  //             time: k[0] / 1000,
  //             value: parseFloat(k[4])
  //         }));
  //         lineSeries.setData(series);
  //         chart.timeScale().fitContent();
  //     } catch (e) {
  //         console.error(`Failed to fetch price of index: ${index.id}`);
  //     }
  // }

  return <div ref={ref}>{/* <div>{price}</div> */}</div>;
}

export default Chart;
