import { useState } from "react";

export default function AggaLogo() {
  // const [hover, setHover] = useState<boolean>(false);

  return (
    <svg
      version="1.1"
      id="agga_logo"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      height="16px"
      viewBox="0 0 659.8 177.6"
      className=" fill-white opacity-70 hover:opacity-100"
      // onMouseOver={() => setHover(true)}
      // onMouseLeave={() => setHover(false)}
    >
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop
            offset="0%"
            style={{ stopColor: "hsl(180deg, 60%, 80%)", stopOpacity: "1" }}
          />
          <stop
            offset="50%"
            style={{ stopColor: "hsl(220deg, 60%, 80%)", stopOpacity: "1" }}
          />
          <stop
            offset="100%"
            style={{ stopColor: "hsl(340deg, 60%, 80%)", stopOpacity: "1" }}
          />
        </linearGradient>
      </defs>
      <path
        className="st0"
        d="M109.8 3h33.7v130.4h-34.7l-4-11.6C94 130.9 80.2 136 64.7 136 25.8 136 0 106.8 0 68.1 0 27.8 27.3.2 64.7.2 80.4.2 94.5 5.6 105 14.7L109.8 3zm-9.7 65.1c0-16.7-11.8-28.5-28.3-28.5S43.5 51.4 43.5 68.1s11.8 28.5 28.3 28.5c16.5.1 28.3-11.7 28.3-28.5zM280.7 3h33.7v109c0 43.5-29.5 65.7-69.9 65.7-35.7 0-62.2-15-65.7-48h42.1c2.2 8.4 11.3 11.8 22.1 11.8 16 0 28-7.6 28-23.1v-3.2c-9.6 6.2-21.4 9.6-34.4 9.6-35.2 0-62-25.1-62-61.7 0-35.7 26.3-63 62-63 15.5 0 29 4.9 39.6 13.8L280.7 3zm-9.4 61c0-15-10.6-25.6-26.8-25.6-16.2 0-26.6 10.6-26.6 25.6 0 15 10.3 25.8 26.6 25.8 16.2 0 26.8-10.8 26.8-25.8zM451.6 3h33.7v109c0 43.5-29.5 65.7-69.9 65.7-35.7 0-62.2-15-65.7-48h42.1c2.2 8.4 11.3 11.8 22.1 11.8 16 0 28-7.6 28-23.1v-3.2c-9.6 6.2-21.4 9.6-34.4 9.6-35.2 0-62-25.1-62-61.7 0-35.7 26.3-63 62-63 15.5 0 29 4.9 39.6 13.8L451.6 3zm-9.4 61c0-15-10.6-25.6-26.8-25.6-16.2 0-26.6 10.6-26.6 25.6 0 15 10.3 25.8 26.6 25.8 16.2 0 26.8-10.8 26.8-25.8zM626.1 3h33.7v130.4h-34.7l-4-11.6c-10.8 9.1-24.6 14.3-40.1 14.3-38.9 0-64.7-29.3-64.7-67.9C516.3 27.9 543.6.3 581 .3c15.7 0 29.8 5.4 40.3 14.5L626.1 3zm-9.6 65.1c0-16.7-11.8-28.5-28.3-28.5s-28.3 11.8-28.3 28.5 11.8 28.5 28.3 28.5 28.3-11.7 28.3-28.5z"
      ></path>
    </svg>
  );
}
