interface DropDownProps {
  title: string;
  toggleAll: () => void;
  productsSelected: Record<string, boolean>;
  setProductsSelected: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
}
export default ({
  title,
  toggleAll,
  productsSelected,
  setProductsSelected,
}: DropDownProps) => {
  return (
    <div className="dropdown group inline-block relative rounded text-sm cursor-pointer z-50 text-slate-200 select-none">
      <div className="dropdown inline-block relative">
        <button className="bg-white/[.04] hover:bg-white/[.1] text-gray-200 font-semibold py-2 px-4 rounded inline-flex items-center transition-all">
          <span className="mr-1">{title}</span>
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
          </svg>
        </button>
        <ul className="dropdown-menu mt-0 rounded backdrop-blur-md bg-neutral-900/50 absolute hidden text-gray-100 font-bold">
          <li
            className="border-b border-neutral-800 p-4 hover:bg-white/10"
            onClick={toggleAll}
          >
            Toggle all
          </li>
          {Object.entries(productsSelected).map(([t, selected]) => (
            <li
              className=""
              key={t}
              onClick={() =>
                setProductsSelected((f) => ({
                  ...f,
                  [t]: !f[t],
                }))
              }
            >
              <a
                className={`flex flex-row items-center hover:bg-white/10 py-4 px-4 whitespace-no-wrap w-64`}
                href="#"
              >
                <div
                  className={`w-4 h-4 mr-3 rounded ${
                    selected ? "bg-indigo-400" : "bg-slate-200"
                  }`}
                />
                {t}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
