import { Index } from "./types";

export const api = {
  url: "https://storage.googleapis.com/daisy-nav-public/indices",
};

export const blockData = {
  "AGGA:DAISY:BE50": {
    title: "Bullseye Fifty",
    description: "Invest in the top 50 digital assets",
    nav: 0,
    weekly_returns: 0,
    link: "https://agga.capital/what-we-offer/bullseye-fifty/",
  },
  "AGGA:DAISY:DEFI20": {
    title: "DeFi Twenty",
    description: "Invest in the top 20 DeFi assets",
    nav: 0,
    weekly_returns: 0,
    link: "https://agga.capital/what-we-offer/defi-twenty/",
  },
  "AGGA:DAISY:META15": {
    title: "Web3 Infrastructure & Metaverse Twenty",
    description:
      "Invest in the top 20 Web 3.0 Infrastructure and Metaverse assets",
    nav: 0,
    weekly_returns: 0,
    link: "https://agga.capital/what-we-offer/web3-infrastructure-and-metaverse-twenty/",
  },
} as Record<string, any>;

export const funds = [
  {
    id: "AGGA:DAISY:BE50",
    name: "Bullseye Fifty",
    indexPrice: 100,
    dailyPnL: 0,
    link: "https://agga.capital/what-we-offer/bullseye-fifty/",
  },
  {
    id: "AGGA:DAISY:DEFI20",
    name: "DeFi Twenty",
    indexPrice: 100,
    dailyPnL: 0,
    link: "https://agga.capital/what-we-offer/defi-twenty/",
  },
  {
    id: "AGGA:DAISY:META15",
    name: "Web3 Infrastructure & Metaverse Twenty",
    indexPrice: 100,
    dailyPnL: 0,
    link: "https://agga.capital/what-we-offer/web3-infrastructure-and-metaverse-twenty/",
  },
] as Index[];

// Tags per DAISY
export const categories = {
  "AGGA:DAISY:BE50": ["Broader-market", "Passive", "Systematic"],
  "AGGA:DAISY:META15": ["Sector-based", "Passive", "Systematic"],
  "AGGA:DAISY:DEFI20": ["Sector-based", "Passive", "Systematic"],
};

export const benchmarks = {
  "BENCHMARK:BTC": true,
  "BENCHMARK:ETH": true,
};

// Timerange selector options
export const options = ["7D", "1M", "3M", "1Y", "YTD", "ALL"];
export const defaultTimerange = "3M";
