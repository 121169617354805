import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from "react-router-dom";
import Home from "./Home";
import IndexScreen from "./IndexScreen";

export default function App() {
  // Menu items
  const links = [
    ["Home", "/"],
    ["Market", "/market"],
  ];

  return (
    <Router>
      <div className="min-h-screen">
        {/* !!! Uncomment this to enable the Menu
        <nav className="container-full text-sm py-3 px-8 space-x-2 border-b border-gray-800 text-gray-100 font-bold flex flex-row items-center">
          <div className="text-sm mr-8">DAISY</div>
          {links.map((link) => (
            <NavLink
              className={({ isActive }) =>
                isActive ? "link link__active" : "link"
              }
              to={link[1]}
            >
              {link[0]}
            </NavLink>
          ))}
        </nav>
            */}

        <Routes>
          <Route path="/index" element={<IndexScreen />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}
