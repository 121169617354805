import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import { Index, SeriesEntry } from "./types";
import { useLocation } from "react-router-dom";
import {
  getDailyPnl,
  getPnlColor,
  getPnlTextColor,
  getProductPrice,
  getStartDate,
  getTotalPnl,
} from "./utils";
import LargeChart from "./components/LargeChart";

interface IndexScreenState {
  index: Index;
  productSeries: SeriesEntry[];
  indexSeries: SeriesEntry[];
}
const customOptions = {
  height: 600,
  grid: {
    vertLines: {
      color: "rgba(197, 203, 206, 0.05)",
    },
    horzLines: {
      color: "rgba(197, 203, 206, 0.05)",
    },
  },
  crosshair: {
    horzLine: {
      visible: true,
      labelVisible: true,
    },
    vertLine: {
      visible: true,
      labelVisible: true,
    },
  },
};

export default () => {
  const location = useLocation();
  const { index, productSeries, indexSeries } =
    location.state as IndexScreenState;

  useEffect(() => {
    console.log("IndexScreen");
  }, []);

  const stats = [
    {
      title: "Index NAV",
      value: getProductPrice(productSeries),
      color: false,
      classFields: "text-indigo-300",
      prefix: "€",
    },
    {
      title: "BTC",
      value: getProductPrice(indexSeries),
      color: false,
      classFields: "text-indigo-300",
      prefix: "€",
    },
    {
      title: "Total Returns",
      value: getTotalPnl(productSeries),
      color: true,
      postFix: "%",
    },
    {
      title: "Daily Returns",
      value: getDailyPnl(productSeries),
      color: true,
      postFix: "%",
    },
  ];

  return (
    <section className="container mx-auto text-white mb-16">
      <div className="py-6 md:py-14 md:px-0 px-4">
        <div className="font-medium mt-8 rounded text-white/[.3] text-xl">
          DAISY
        </div>
        <div className="font-medium mt-2 rounded-xl text-white text-2xl">
          {index.name}
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 md:gap-4 gap-1 mt-6">
        {stats.map(({ title, value, color, classFields, postFix, prefix }) => (
          <div>
            <div
              className={`rounded-md py-6 px-6 font-bold bg-white/[.01] hover:bg-white/[.05] transition duration-150 ease-out`}
            >
              <div className="text-sm mb-4 font-semibold text-white/[.5]">
                {title}
              </div>
              <div
                className={`font-medium text-md md:text-xl ${classFields} ${
                  color ? getPnlTextColor(value) : ""
                }`}
              >
                {prefix}
                {value}
                {postFix}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-16">
        {indexSeries && indexSeries.length > 0 ? (
          <LargeChart
            index={index}
            series={indexSeries}
            productSeries={productSeries}
            customOptions={customOptions}
          />
        ) : (
          <div className="text-white" style={{ height: 300 }}>
            loading...
          </div>
        )}
      </div>
    </section>
  );
};
