import React, { useEffect, useMemo, useRef, useState } from "react";
import "./App.css";
import { Index, IndicesData, IndexEntry } from "./types";
import IndexComponent from "./IndexComponent";
import MultiChart from "./components/MultiChart";
import AggaLogo from "./icons/AggaLogo";
import DropDown from "./DropDown";
import Blocks from "./Blocks";
import TimeRangeSelector from "./TimeRangeSelector";
import { stringify } from "querystring";
import { getSourceMapRange } from "typescript";
import { mapRange } from "./utils";
import * as config from "./config";

export default function Home() {
  const [indexData, setIndexData] = useState<IndicesData | null>(null);
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [timeRange, setTimeRange] = useState<string>(config.defaultTimerange);

  const [multiChartData, setMultiChartData] = useState<IndicesData | null>(
    null
  );

  const [productsSelected, setProductsSelected] = useState<
    Record<string, boolean>
  >({});
  const [benchmarksSelected, setBenchmarksSelected] = useState<
    Record<string, boolean>
  >({});

  const allProducts = useMemo(
    () => ({ ...productsSelected, ...benchmarksSelected }),
    [productsSelected, benchmarksSelected]
  );

  useEffect(() => {
    fetchPrices();
  }, []);

  useEffect(() => {
    updateMultiChart(timeRange);
  }, [indexData]);

  const fetchPrices = async () => {
    /*
    Get navs from the storage bucket and save in the right datastructure
    */
    setLoading(true);
    try {
      const response = await fetch(config.api.url);
      const data = await response.json();
      console.log(data.created_at);
      const date = new Date(data.created_at.substring(0, 19) + "Z");
      setLastUpdated(date);
      data.categories = config.categories;
      setIndexData(data);

      const selectedProducts = Object.keys(data.data)
        .filter((k) => k in config.categories)
        .reduce((o, k) => ({ ...o, [k]: true }), {});
      setProductsSelected(selectedProducts);
      setBenchmarksSelected(config.benchmarks);
      setLoading(false);
    } catch (e) {
      console.error("Failed to fetch data");
      console.error(e);
    }
  };

  const updateTimeRange = (range: string) => {
    console.log(`update range: ${range}`);
    setTimeRange(range);
    updateMultiChart(range);
  };

  const filterData = (values: IndexEntry[], date: Date) => {
    return values.filter((v) => new Date(v.time) >= date);
  };

  const normalizeNAV = (values: IndexEntry[]) => {
    const firstValue = values[0].price / 100;
    return values.map((v) => ({ price: v.price / firstValue, time: v.time }));
  };

  const updateMultiChart = (range: string) => {
    if (indexData?.data == null) {
      console.log("skip");
      return;
    }

    if (range == "ALL") {
      setMultiChartData({
        createdAt: indexData.createdAt,
        data: { ...indexData.data },
        categories: indexData.categories,
      });
      return;
    }

    const d = new Date();
    d.setDate(d.getDate() - 1);
    const startDate = mapRange[range](d);
    console.log(startDate);

    const newData = Object.entries(indexData?.data).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: normalizeNAV(filterData(value, startDate)),
      }),
      {} as Record<string, IndexEntry[]>
    );

    console.log(`update multichart for range ${range}`);
    setMultiChartData({
      createdAt: indexData.createdAt,
      data: newData,
      categories: indexData.categories,
    });
  };

  const toggleAllProducts = () => {
    const containsTrue = Object.values(productsSelected).some((t) => t);
    const newSelection = Object.keys(productsSelected).reduce(
      (acc, k) => ({ ...acc, [k]: !containsTrue }),
      {}
    );
    setProductsSelected(newSelection);
  };

  const toggleAllBenchmarks = () => {
    const containsTrue = Object.values(benchmarksSelected).some((t) => t);
    const newSelection = Object.keys(benchmarksSelected).reduce(
      (acc, k) => ({ ...acc, [k]: !containsTrue }),
      {}
    );
    setBenchmarksSelected(newSelection);
  };

  return (
    <section className="container mx-auto text-white mb-16">
      {/* Loading screen */}
      {loading && (
        <div className="z-50 bg-white/[.1] flex flex-row items-center backdrop-blur-sm font-bold rounded-md py-4 px-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <svg
            aria-hidden="true"
            className="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="white"
              className="fill-white/10"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="ml-4">Loading...</span>
        </div>
      )}

      {/* Header */}
      <div className="py-14">
        <a href="https://agga.capital" className="px-12 block">
          <AggaLogo />
        </a>
        <div className="mt-4 px-8 select-none text-white text-3xl mx-4 flex flex-row items-center">
          NAV dashboard
          <div className="ml-4 bg-white/5 rounded-full text-white-700 font-bold text-xs px-2.5 py-0.5">
            beta
          </div>
        </div>
        <div className="select-none bg-opacity font-semibold rounded-xl px-8 mt-2 text-white/40 mx-4">
          Indicative NAV's for the DAISY's since 01-02-2022. Past performance is
          no indication of future performance.
        </div>
        <div className="select-none font-semibold px-8 text-sm mt-2 text-white/20 mx-4">
          Last updated on {lastUpdated?.toLocaleString()}
        </div>
      </div>

      <Blocks funds={config.funds} data={indexData?.data} />

      {!loading && (
        <>
          {/* Interactive multichart */}
          <div className="hidden md:block bg-white/[.01] py-8 pr-8 md:rounded-2xl transition duration-150 ease-out border border-transparent hover:border-indigo-500/[.2] md:mx-4 mb-4">
            <div className="flex flex-col pl-12 mb-8 md:flex-row">
              <div className="select-none mr-4 text-2xl text-gray-100">
                DAISY's
              </div>

              <div className="flex flex-row grow space-x-2 md:mt-0 mt-2">
                <DropDown
                  title="Funds"
                  toggleAll={toggleAllProducts}
                  productsSelected={productsSelected}
                  setProductsSelected={setProductsSelected}
                />
                <DropDown
                  title="Benchmarks"
                  toggleAll={toggleAllBenchmarks}
                  productsSelected={benchmarksSelected}
                  setProductsSelected={setBenchmarksSelected}
                />
                <div className="grow"></div>
                <TimeRangeSelector update={updateTimeRange} />
              </div>
            </div>
            {multiChartData && (
              <MultiChart
                indexData={multiChartData}
                selectedProducts={allProducts}
              />
            )}
          </div>

          {/* Individual charts */}
          <div className="space-y-4">
            {indexData != null &&
              config.funds.map((fund) => (
                <div key={fund.id}>
                  <IndexComponent
                    key={fund.id}
                    categories={indexData.categories[fund.id]}
                    index={fund}
                    _productSeries={indexData?.data[fund.id]}
                    _indexSeries={indexData.data["BENCHMARK:BTC"]}
                  />
                </div>
              ))}
          </div>
        </>
      )}
    </section>
  );
}
