import "./App.css";

const headers = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
  "Yearly Returns",
];

export default ({ returnsData }: { returnsData: Record<number, number[]> }) => {
  const format = (d: number | null) => {
    if (d == null) {
      return "-";
    }
    return `${d > 0 ? "+" : ""}${d.toFixed(1)}%`;
  };

  return (
    <table className="table-auto border-separate select-none w-full px-1 py-2 order bg-white/[.01] rounded-xl">
      <thead>
        <tr className="m-4">
          <th></th>
          {headers.map((h, i) => (
            <th
              key={i}
              className="text-sm border-l border-white/5 tracking-wider text-center"
            >
              <span className="opacity-40">{h}</span>
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {Object.entries(returnsData).map(([year, data], j) => (
          <tr key={j}>
            <td className="font-bold text-sm opacity-60 text-right pr-3">
              {year}
            </td>
            {data.map((d, i) => (
              <td
                key={i}
                className="py-2 hover:bg-white/5 text-xs md:text-sm font-bold text-center border-l border-white/5"
              >
                {format(d)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
